import React from 'react';
import { Link } from 'gatsby';
import tags from '../../data/tags';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

import SEO from '../../components/seo';
import TemplateLayout from '../../templates/layout';
import Breadcrumb from '../../components/breadcrumb';

const IndexPage = () => (
  <TemplateLayout>
    <SEO title="Datayears | Tags" />
    <Breadcrumb
        items={[
            { name: 'Home', linkTo: '/' },
            { name: 'Tags' },
        ]}
    />
    {tags.map(tag => (
        <div key={tag}>
            <Link to={`/tags/${kebabCase(tag)}`}>
                {startCase(tag)}
            </Link>
        </div>
    ))}
  </TemplateLayout>
);

export default IndexPage;
